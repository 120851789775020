<template>
  <section>
    <tab-navbar style="visibility: hidden" class="mb-4" :options="tabs" :slant="true"></tab-navbar>
    <b-card class="dashboard-card shadow-sm mr-t-4">
      <router-view @handleSave="changeTab" />
    </b-card>
  </section>
</template>
<script>
import TabNavbar from "../../../components/Common/TabNavbar.vue";
import { bus } from "../../../main";

export default {
  props: ["applicationID"],
  data() {
    return {
      tabs: [
        { text: "Application", to: "ApplicationInfo" },
        { text: "Payment", to: "Payment" },
        { text: "Survey", to: "Survey" },
        { text: "POC", to: "POC" },
        { text: "Accreditation & Certification", to: "AccreditationCertification" },
      ],
    };
  },
  components: {
    "tab-navbar": TabNavbar,
  },
  mounted() {
    bus.$on("switchToPayment", () => {
      this.changeTab("ApplicationInfo");
    });
  },
  methods: {
    changeTab(route) {
      var currentTabIndex = this.tabs.findIndex((t) => t.to === route);
      if (currentTabIndex !== this.tabs.length - 1) {
        this.$router.push({
          name: this.tabs[currentTabIndex + 1].to,
          params: { applicationID: this.applicationID },
        });
      }
    },
  },
};
</script>
